export interface OrderFormInputs {
  ingredients: string[];
  ingredientGroup: string[];
  comment: string;
}

export enum ViewModesEnum {
  Normal = 'NORMAL',
  Medium = 'MEDIUM',
  Compact = 'COMPACT',
}
