import { FC, useState } from 'react';

import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

import AppHeader from './components/AppHeader';
import AppContent from './components/AppContent';

import { HEADER_HEIGHT } from '@/constants';
import AppDrawer from '@/components/AppLayout/components/AppDrawer';

const AppLayout: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      }}
    >
      <CssBaseline />

      <AppHeader toggleDrawer={handleToggleDrawer} />
      <AppDrawer drawerOpen={drawerOpen} toggleDrawer={handleToggleDrawer} />
      <AppContent>
        <Outlet />
      </AppContent>
    </Box>
  );
};

export default AppLayout;
