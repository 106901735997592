import { AxiosResponse } from 'axios';

import apiService from '@/services/api';
import { CreateOrderResponse, OrderDto, OrderRequest, OrderResponse } from '@/store/slices/order/types';

export const orderApi = {
  async fetchAll(query: OrderRequest): Promise<AxiosResponse<OrderResponse>> {
    return apiService.v1.get('/orders', {
      params: query,
    });
  },
  async create(orderData: OrderDto): Promise<AxiosResponse<CreateOrderResponse>> {
    return apiService.v1.post('/orders', orderData);
  },
};
