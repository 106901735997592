import { AxiosResponse } from 'axios';

import apiService from '@/services/api';
import { AddressQuery, AddressDto, PlaceQuery, PlacesResponseDto } from '@/store/slices/addresses/types';

export const addressesApi = {
  async fetchAll(query: AddressQuery): Promise<AxiosResponse<AddressDto[]>> {
    return apiService.v1.get('/v1/address/searchAddress', {
      params: query,
    });
  },

  async fetchPlaces(query: PlaceQuery): Promise<AxiosResponse<PlacesResponseDto>> {
    return await apiService.v1.get('/v1/address/autocomplete', {
      params: query,
    });
  },
};
