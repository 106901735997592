import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { TokensDto } from '@/types/api';
import { AuthDto } from '@/store/slices/auth/types';
import { StorageKeysEnum, storageService } from '@/services/storage';
import { authApi } from '@/store/slices/auth/api';
import { removeTokens, storeTokens } from '@/helpers';
import { createApiError } from '@/store/helpers';

interface AuthState {
  entity: boolean;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: AuthState = {
  entity: !!storageService.getItem(StorageKeysEnum.AccessToken),
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const signInThunk = createAsyncThunk<TokensDto, AuthDto>('auth/signIn', async (params, thunkAPI) => {
  try {
    const { data } = await authApi.signIn(params);
    storeTokens(data);
    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(createApiError(e));
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut(state) {
      removeTokens();
      state.entity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInThunk.fulfilled, (state) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entity = true;
      })
      .addCase(signInThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(signInThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.entity = false;
        state.error = error;
      });
  },
});

export const selectIsLoggedIn = (state: RootState) => state.auth.entity;
