import { AxiosResponse } from 'axios';

import { CategoriesResponseDto } from '@/store/slices/categories/types';
import apiService from '@/services/api';

export const categoriesApi = {
  async fetchAll(): Promise<AxiosResponse<CategoriesResponseDto>> {
    return apiService.v1.get('/categories', { params: { visibleInPos: true } });
  },
};
