import { FC, useEffect, useState } from 'react';

import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { grey } from '@mui/material/colors';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { fetchProductsThunk, productsSlice, selectProducts } from '@/store/slices/products';
import { HEADER_HEIGHT, LIST_BREAKPOINTS } from '@/constants';
import { fetchCategoriesThunk, selectCategory } from '@/store/slices/categories';
import AppNavLink from '@/components/AppNavLink';
import { IngredientDto, ProductDto } from '@/store/slices/products/types';
import OrderDialog from '@/routes/Categories/routes/ProductsList/components/OrderDialog';
import { addToCartThunk } from '@/store/slices/cart';
import { OrderFormInputs, ViewModesEnum } from '@/routes/Categories/routes/ProductsList/types';
import { selectCartQue } from '@/store/slices/cartSelectedQue';
import { StorageKeysEnum, storageService } from '@/services/storage';

const ProductsList: FC = () => {
  const dispatch = useAppDispatch();
  const { categoryId } = useParams();
  const cartQue = useAppSelector(selectCartQue);
  const category = useAppSelector(selectCategory(categoryId));
  const products = useAppSelector(selectProducts);
  const [selectedProduct, setSelectedProduct] = useState<ProductDto | null>(null);
  const [viewMode, setViewMode] = useState<ViewModesEnum>(ViewModesEnum.Normal);

  useEffect(() => {
    const storedViewMode = storageService.getItem(StorageKeysEnum.ViewMode);
    if (storedViewMode && (Object.values(ViewModesEnum) as string[]).includes(storedViewMode)) {
      setViewMode(storedViewMode as ViewModesEnum);
    }
  }, []);

  useEffect(() => {
    dispatch(productsSlice.actions.reset());
    dispatch(fetchCategoriesThunk());
    dispatch(fetchProductsThunk({ _id: categoryId }));
  }, [dispatch, categoryId]);

  const handleCloseIngredient = () => {
    setSelectedProduct(null);
  };

  const handleProductClick = (product: ProductDto) => {
    dispatch(
      addToCartThunk({
        que: cartQue,
        orderProduct: {
          item: product,
          quantity: 1,
          comment: '',
          ingredients: [],
        },
      }),
    );
  };

  const handleCommentClick = (product: ProductDto) => {
    setSelectedProduct(product);
  };

  const handleSubmitIngredients = async (data: OrderFormInputs) => {
    if (!selectedProduct) return;

    const groups: IngredientDto[] = [];
    selectedProduct.ingredientGroups.forEach((ingredientGroups) => {
      const ingredient = ingredientGroups.ingredients.find((ingredient) =>
        data.ingredientGroup.includes(ingredient._id),
      );

      if (ingredient) {
        groups.push(ingredient);
      }
    });

    const ingredients = selectedProduct.ingredients.filter((ingredients) => data.ingredients.includes(ingredients._id));

    const response = await dispatch(
      addToCartThunk({
        que: cartQue,
        orderProduct: {
          item: selectedProduct,
          quantity: 1,
          comment: data.comment,
          ingredients: [...ingredients, ...groups],
        },
      }),
    );

    if (addToCartThunk.fulfilled.match(response)) {
      handleCloseIngredient();
    }
  };

  const handleChangeViewMode = (viewMode: ViewModesEnum) => {
    storageService.setItem(StorageKeysEnum.ViewMode, `${viewMode}`);
    setViewMode(viewMode);
  };

  return (
    <>
      <Box
        sx={{
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          overflow: 'auto',
          p: 1,
          backgroundColor: grey[200],
        }}
      >
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <AppNavLink to="/categories">Categories</AppNavLink>

            <Typography variant="body2" color="text.primary">
              {category?.categoryName}
            </Typography>
          </Breadcrumbs>

          <ToggleButtonGroup
            value={viewMode}
            size="small"
            color="primary"
            exclusive
            onChange={(e, value) => handleChangeViewMode(value)}
          >
            <ToggleButton value={ViewModesEnum.Normal}>
              <DensityLargeIcon />
            </ToggleButton>
            <ToggleButton value={ViewModesEnum.Medium}>
              <DensityMediumIcon />
            </ToggleButton>
            <ToggleButton value={ViewModesEnum.Compact}>
              <DensitySmallIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Grid container spacing={1}>
          {products.map((product) => (
            <Grid item key={product._id} {...LIST_BREAKPOINTS}>
              <Card variant="outlined">
                <CardActionArea onClick={() => handleProductClick(product)} sx={{ p: 0 }}>
                  <CardMedia
                    component="img"
                    height={
                      viewMode === ViewModesEnum.Normal ? '150' : viewMode === ViewModesEnum.Medium ? '100' : '50'
                    }
                    image={`${process.env.REACT_APP_STORAGE_URL}/${product.image}`}
                    alt="Product image"
                    sx={{ objectFit: 'contain' }}
                  />

                  <CardContent>
                    <Tooltip title={product.itemName}>
                      <Typography noWrap key={product._id}>
                        {product.itemName}
                      </Typography>
                    </Tooltip>
                  </CardContent>
                </CardActionArea>

                <CardActionArea sx={{ backgroundColor: grey['50'] }}>
                  <CardActions
                    color="primary"
                    onClick={() => handleCommentClick(product)}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <AddCommentIcon fontSize="small" color="primary" />

                    {!!(product.ingredientGroups.length || product.ingredients.length) && (
                      <FastfoodIcon fontSize="small" color="primary" />
                    )}
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <OrderDialog
        product={selectedProduct}
        open={!!selectedProduct}
        onClose={handleCloseIngredient}
        onSubmit={handleSubmitIngredients}
      />
    </>
  );
};

export default ProductsList;
