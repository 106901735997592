import { AxiosResponse } from 'axios';

import apiService from '@/services/api';
import { MeResponse } from '@/store/slices/me/types';

export const meApi = {
  async fetchAll(): Promise<AxiosResponse<MeResponse>> {
    return apiService.v1.get('/me');
  },
};
