import { FC, ReactNode } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { alertSlice, selectAlert } from '@/store/slices/alert';
import AppSnackBar from '@/components/AppSnackBar';

interface Props {
  children?: ReactNode;
}

const AppAlertProvider: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const alert = useAppSelector(selectAlert);

  const handleClose = () => {
    dispatch(alertSlice.actions.deleteAlert());
  };

  return (
    <>
      {alert && <AppSnackBar open={!!alert} onClose={handleClose} type={alert.type} message={alert.message} />}
      {children}
    </>
  );
};

export default AppAlertProvider;
