import { forwardRef, ReactElement, Ref } from 'react';

import { TransitionProps } from '@mui/material/transitions';
import { Slide } from '@mui/material';

const Transition = forwardRef(function TransitionFunction(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  const { children, ...restProps } = props;
  return (
    <Slide direction="up" ref={ref} {...restProps}>
      {children}
    </Slide>
  );
});

export default Transition;
