import { AxiosResponse } from 'axios';

import { ProductsRequestDto, ProductsResponseDto } from '@/store/slices/products/types';
import apiService from '@/services/api';

export const productsApi = {
  async fetchAll(params: ProductsRequestDto): Promise<AxiosResponse<ProductsResponseDto>> {
    return apiService.v1.get('/items', {
      params: {
        category: params._id,
        visibleInPos: true,
      },
    });
  },
};
