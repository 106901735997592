import { AxiosError, AxiosRequestConfig } from 'axios';

import { StorageKeysEnum, storageService } from '@/services/storage';
import apiService from '@/services/api';
import { storeTokens } from '@/helpers';
import store from '@/store';
import { authSlice } from '@/store/slices/auth';

export const axiosOnFulfilled = async function (config: AxiosRequestConfig) {
  const refreshToken = storageService.getItem(StorageKeysEnum.RefreshToken);
  const expiresIn = storageService.getItem(StorageKeysEnum.ExpiresIn);

  if (expiresIn && Date.now() > Number(expiresIn)) {
    try {
      const { data } = await apiService.auth.post('/refresh-token', {
        refreshToken,
      });

      storeTokens(data);
    } catch (e) {
      store.dispatch(authSlice.actions.signOut());
      return config;
    }
  }

  const accessToken = storageService.getItem(StorageKeysEnum.AccessToken);

  if (config.headers === undefined) {
    config.headers = {};
  }

  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`;
  }

  return config;
};

export const axiosOnRejected = function (error: AxiosError) {
  return Promise.reject(error);
};
