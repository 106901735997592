import { FC, useCallback } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup, TextField } from '@mui/material';

import AutocompletePhoneNumber from '@/components/Autocomplete/AutocompletePhoneNumber';
import PlacesAddress from '@/components/Autocomplete/PlacesAddress';
import { AddressDto } from '@/store/slices/addresses/types';

const PaymentFormDeliveryInputs: FC = () => {
  const { control, setValue } = useFormContext();

  const handleOnChangePhoneNumber = useCallback(
    (address: AddressDto) => {
      setValue('phoneNumber', address.phoneNumber);
      setValue('address', address.address);
      setValue('entrance', address.entrance);
      setValue('floor', address.floor);
      setValue('apartment', address.apartment);
      setValue('description', address.description);
    },
    [setValue],
  );

  return (
    <>
      <FormGroup>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompletePhoneNumber
              value={value}
              onChange={handleOnChangePhoneNumber}
              onInputChange={onChange}
              size="small"
            />
          )}
        />

        <Controller
          name="address"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PlacesAddress size="small" onChange={(place) => onChange(place.description)} value={value} />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => <TextField {...field} size="small" multiline rows={2} margin="dense" label="Notes" />}
        />

        <Controller
          name="entrance"
          control={control}
          render={({ field }) => <TextField {...field} size="small" margin="dense" label="Entrance" />}
        />

        <Controller
          name="floor"
          control={control}
          render={({ field }) => <TextField {...field} size="small" margin="dense" label="Floor" />}
        />

        <Controller
          name="apartment"
          control={control}
          render={({ field }) => <TextField {...field} size="small" margin="dense" label="Apartment" />}
        />
      </FormGroup>
    </>
  );
};

export default PaymentFormDeliveryInputs;
