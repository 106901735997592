import { FC, useEffect, useState } from 'react';

import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { fetchCategoriesThunk, selectCategories } from '@/store/slices/categories';
import { HEADER_HEIGHT, LIST_BREAKPOINTS } from '@/constants';
import { ViewModesEnum } from '@/routes/Categories/routes/ProductsList/types';
import { StorageKeysEnum, storageService } from '@/services/storage';

const CategoriesList: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const categories = useAppSelector(selectCategories);

  // TODO move sizes part to separate component. There is code duplication
  const [viewMode, setViewMode] = useState<ViewModesEnum>(ViewModesEnum.Normal);

  useEffect(() => {
    const storedViewMode = storageService.getItem(StorageKeysEnum.ViewMode);
    if (storedViewMode && (Object.values(ViewModesEnum) as string[]).includes(storedViewMode)) {
      setViewMode(storedViewMode as ViewModesEnum);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCategoriesThunk());
  }, [dispatch]);

  const handleChangeViewMode = (viewMode: ViewModesEnum) => {
    storageService.setItem(StorageKeysEnum.ViewMode, `${viewMode}`);
    setViewMode(viewMode);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        overflow: 'auto',
        p: 1,
        backgroundColor: grey[200],
      }}
    >
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Categories</Typography>
        </Breadcrumbs>

        <ToggleButtonGroup
          value={viewMode}
          size="small"
          color="primary"
          exclusive
          onChange={(e, value) => handleChangeViewMode(value)}
        >
          <ToggleButton value={ViewModesEnum.Normal}>
            <DensityLargeIcon />
          </ToggleButton>
          <ToggleButton value={ViewModesEnum.Medium}>
            <DensityMediumIcon />
          </ToggleButton>
          <ToggleButton value={ViewModesEnum.Compact}>
            <DensitySmallIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={1}>
        {categories.map((category) => (
          <Grid item key={category._id} {...LIST_BREAKPOINTS}>
            <Card variant="outlined">
              <CardActionArea onClick={() => navigate(`/categories/${category._id}`)}>
                <CardMedia
                  component="img"
                  height={viewMode === ViewModesEnum.Normal ? '150' : viewMode === ViewModesEnum.Medium ? '100' : '50'}
                  image={`${process.env.REACT_APP_STORAGE_URL}/${category.image}`}
                  alt="Category image"
                  sx={{ objectFit: 'contain' }}
                />

                <CardContent>
                  <Tooltip title={category.categoryName}>
                    <Typography noWrap key={category._id}>
                      {category.categoryName}
                    </Typography>
                  </Tooltip>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CategoriesList;
