import { FC } from 'react';
import * as React from 'react';

import { AppBar, Avatar, Box, Button, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import { NavLink as RouterNavLink, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '@/assets/images/logo.jpg';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { authSlice, selectIsLoggedIn } from '@/store/slices/auth';

interface Props {
  toggleDrawer: () => void;
}

const AppHeader: FC<Props> = ({ toggleDrawer }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const handleSignOut = () => {
    dispatch(authSlice.actions.signOut());
  };

  return (
    <AppBar color="transparent" position="static" elevation={0} component="nav">
      <Toolbar>
        {isLoggedIn && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <IconButton
          edge="start"
          sx={{ p: 0, mr: 1 }}
          onClick={() => {
            navigate('/categories');
          }}
        >
          <Avatar alt="Remy Sharp" src={logo} />
        </IconButton>

        <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
          {process.env.REACT_APP_TITLE}
        </Typography>

        {isLoggedIn && (
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Button
              size="small"
              sx={{ mr: 1 }}
              variant={location.pathname.includes('/categories') ? 'contained' : 'text'}
              component={RouterNavLink}
              to="/categories"
            >
              Menu
            </Button>

            <Button
              size="small"
              sx={{ mr: 1 }}
              variant={location.pathname.includes('/history') ? 'contained' : 'text'}
              component={RouterNavLink}
              to="/history"
            >
              History
            </Button>

            <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

            <Button size="small" onClick={handleSignOut}>
              Sign Out
            </Button>
          </Box>
        )}
      </Toolbar>

      <Divider />
    </AppBar>
  );
};

export default AppHeader;
