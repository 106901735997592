import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { meApi } from '@/store/slices/me/api';
import { Me } from '@/store/slices/me/types';
import { createApiError } from '@/store/helpers';

interface MeState {
  entity: Me | null;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: MeState = {
  entity: null,
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const fetchMeThunk = createAsyncThunk<Me, void>('me/fetch', async (params, thunkAPI) => {
  try {
    const response = await meApi.fetchAll();
    return response.data.user;
  } catch (e) {
    return thunkAPI.rejectWithValue(createApiError(e));
  }
});

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entity = payload;
      })
      .addCase(fetchMeThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchMeThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      });
  },
});

export const selectMe = (state: RootState) => state.me.entity || null;
