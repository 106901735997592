import { FC } from 'react';
import * as React from 'react';

import { Divider } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from 'react-router-dom';

import { DRAWER_HEIGHT } from '@/constants';
import AppNavLink from '@/components/AppNavLink';
import { authSlice } from '@/store/slices/auth';
import { useAppDispatch } from '@/hooks/store';
interface Props {
  drawerOpen: boolean;
  toggleDrawer: () => void;
}
const AppDrawer: FC<Props> = ({ drawerOpen, toggleDrawer }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleSignOut = () => {
    dispatch(authSlice.actions.signOut());
  };
  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_HEIGHT },
        }}
      >
        <Box onClick={toggleDrawer} sx={{ textAlign: 'center' }}>
          <List component="nav">
            <ListItemButton
              selected={location.pathname.includes('/categories')}
              component={AppNavLink}
              to="/categories"
            >
              <ListItemButton>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItemButton>

            <ListItemButton
              selected={location.pathname.includes('/history')}
              component={AppNavLink}
              to="/history"
              sx={{ flexGrow: 1 }}
            >
              <ListItemButton>
                <ListItemText primary="Orders" />
              </ListItemButton>
            </ListItemButton>

            <Divider />

            <ListItemButton sx={{ flexGrow: 1 }}>
              <ListItemButton onClick={handleSignOut}>
                <ListItemText primary="Sign Out" />
              </ListItemButton>
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default AppDrawer;
