import { AxiosResponse } from 'axios';

import apiService from '@/services/api';
import { AuthDto } from '@/store/slices/auth/types';
import { TokensDto } from '@/types/api';

export const authApi = {
  async signIn(params: AuthDto): Promise<AxiosResponse<TokensDto>> {
    return apiService.auth.post('/sign-in', params);
  },
};
