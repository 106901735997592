import { FC, ReactNode } from 'react';

import { Alert, AlertColor, Snackbar } from '@mui/material';

interface Props {
  open: boolean;
  type: AlertColor;
  onClose: () => void;
  message: ReactNode;
}

const AppSnackBar: FC<Props> = ({ open, onClose, type, message }) => (
  <Snackbar
    open={open}
    autoHideDuration={3000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert variant="filled" elevation={6} onClose={onClose} severity={type || ''} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);

export default AppSnackBar;
