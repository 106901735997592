import { FC, memo, SyntheticEvent, useCallback, useEffect, useRef } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Box, debounce } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { addressesSlice, fetchPlacesThunk, selectPlaces } from '@/store/slices/addresses';
import { PlaceDto } from '@/store/slices/addresses/types';

export type PlacesAddressProps = Omit<
  AutocompleteProps<PlaceDto, undefined, true, true>,
  'options' | 'renderInput' | 'onChange' | 'onInputChange'
> & {
  onChange?: (address: PlaceDto) => void;
  onInputChange?: (value: string) => void;
};

const PlacesAddress: FC<PlacesAddressProps> = ({ onChange, onInputChange, ...props }) => {
  const places = useAppSelector(selectPlaces);
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(addressesSlice.actions.resetPlaces());
    },
    [dispatch],
  );

  const handleOnChange = useCallback(
    (e: SyntheticEvent, place: string | PlaceDto) => {
      if (place && typeof place === 'object') {
        onChange?.(place);
      }
    },
    [onChange],
  );

  const debouncedFetchThunk = useRef(
    debounce((input: string) => {
      dispatch(fetchPlacesThunk({ input }));
    }, 500),
  ).current;

  const handleOnInputChange = useCallback(
    (e: SyntheticEvent, value: string, reason: string) => {
      onInputChange?.(value);
      if (reason === 'clear') onChange?.({ description: '' } as PlaceDto);
      if (reason === 'input' && value) debouncedFetchThunk(value);
    },
    [debouncedFetchThunk, onChange, onInputChange],
  );

  return (
    <Autocomplete
      {...props}
      options={places}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      renderInput={(params) => <TextField {...params} margin="dense" label="Address" />}
      filterOptions={(options) => options}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props} key={option.place_id}>
          {option.description}
        </Box>
      )}
    />
  );
};

export default memo(PlacesAddress);
