import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '@/store/types';

interface CartQueState {
  entity: number;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: CartQueState = {
  entity: 0,
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const cartSelectedQueSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    update(state, action) {
      state.entity = action.payload;
    },
  },
});

export const selectCartQue = (state: RootState) => state.cartSelectedQue.entity;
