import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { addressesApi } from './api';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { AddressDto, AddressQuery, PlaceDto, PlaceQuery, PlacesResponseDto } from '@/store/slices/addresses/types';
import { createApiError } from '@/store/helpers';

interface AddressesState {
  addresses: AddressDto[];
  places: PlaceDto[];
  loading: StoreLoadingEnum;
  placesLoading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: AddressesState = {
  addresses: [],
  places: [],
  loading: StoreLoadingEnum.Idle,
  placesLoading: StoreLoadingEnum.Idle,
  error: null,
};

export const fetchAddressesThunk = createAsyncThunk<AddressDto[], AddressQuery>(
  'address/fetchAll',
  async (params, thunkAPI) => {
    try {
      const { data } = await addressesApi.fetchAll(params);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const fetchPlacesThunk = createAsyncThunk<PlacesResponseDto, PlaceQuery>(
  'places/fetchAll',
  async (params, thunkAPI) => {
    try {
      const { data } = await addressesApi.fetchPlaces(params);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    reset(state) {
      state.addresses = [];
    },
    resetPlaces(state) {
      state.places = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressesThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.addresses = payload;
      })
      .addCase(fetchAddressesThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchAddressesThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(fetchPlacesThunk.fulfilled, (state, { payload }) => {
        state.placesLoading = StoreLoadingEnum.Succeeded;
        state.places = payload.predictions;
      })
      .addCase(fetchPlacesThunk.pending, (state) => {
        state.placesLoading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchPlacesThunk.rejected, (state, { error }) => {
        state.placesLoading = StoreLoadingEnum.Failed;
        state.error = error;
      });
  },
});

export const selectAddresses = (state: RootState) => state.addresses.addresses;
export const selectPlaces = (state: RootState) => state.addresses.places;
