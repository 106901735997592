import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import OrdersList from '@/routes/History/components/OrdersList';

const History: FC = () => (
  <Stack spacing={1} sx={{ p: 2 }}>
    <Box>
      <Typography variant="h4">Orders</Typography>
    </Box>

    <OrdersList />
  </Stack>
);

export default History;
