import { FC, memo } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { numberFormat } from '@/helpers';
import { DeliveryInfoResponse } from '@/store/slices/deliveryInfo/types';

interface Props {
  amount: number;
  deliveryInfo: DeliveryInfoResponse | null;
  cartPrice: number;
}

const PaymentPricesInfo: FC<Props> = ({ deliveryInfo, amount, cartPrice }) => (
  <>
    <Box>
      <Typography variant="body1">Price: {cartPrice} AMD</Typography>

      <Typography variant="body1">Delivery Price: {numberFormat(deliveryInfo?.deliveryPrice || 0)} AMD</Typography>

      {deliveryInfo?.discountPercent ? (
        <Typography variant="body1">
          Discount: {deliveryInfo.discountPercent}% ({deliveryInfo?.discountValue} AMD)
        </Typography>
      ) : (
        ''
      )}

      {deliveryInfo?.discountPercent && deliveryInfo?.discountedPrice ? (
        <Typography variant="body1">Discounted Price: {numberFormat(deliveryInfo.discountedPrice || 0)} AMD</Typography>
      ) : (
        ''
      )}

      <Typography variant="h6">Total Price: {numberFormat(deliveryInfo?.totalPrice || 0)} AMD</Typography>
    </Box>

    <Divider />

    <Box>
      <Typography variant="h6" component="span" sx={{ mr: 1 }}>
        Change:
      </Typography>

      <Typography variant="h6" component="span" color="primary">
        <Typography variant="h6" component="span" sx={{ mr: 1 }}>
          {numberFormat(amount - Number(deliveryInfo?.totalPrice) > 0 ? amount - Number(deliveryInfo?.totalPrice) : 0)}
        </Typography>

        <Typography variant="h6" component="span">
          AMD
        </Typography>
      </Typography>
    </Box>
  </>
);

export default memo(PaymentPricesInfo);
