import { FC } from 'react';

import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';

interface Props {
  onClick: (value: string) => void;
}

const KEYBOARD_KEYS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '00', '0', '⌫'];
const AppKeypad: FC<Props> = ({ onClick }) => (
  <Grid container spacing={1}>
    {KEYBOARD_KEYS.map((item) => (
      <Grid key={item} item xs={4}>
        <Card onClick={() => onClick(item)} elevation={3}>
          <CardActionArea
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 100,
            }}
          >
            <CardContent>
              <Typography variant="h4">{item}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
);

export default AppKeypad;
