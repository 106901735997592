import { isEqual, pick, without } from 'lodash';

import { StorageKeysEnum, storageService } from '@/services/storage';
import { CartResponseDto } from '@/store/slices/cart/types';
import { OrderProductDto } from '@/store/slices/order/types';

export const cartApi = {
  fetchAll(): CartResponseDto {
    const data = storageService.getItem(StorageKeysEnum.Products);

    if (!data) {
      const newData = [[]];
      storageService.setItem(StorageKeysEnum.Products, JSON.stringify(newData));
      return newData;
    }

    return JSON.parse(data);
  },

  add(que: number, orderProduct: OrderProductDto): CartResponseDto {
    const allProducts = this.fetchAll();
    const products = allProducts[que];

    const existsOrderProduct = products.find((value) => {
      const keysWithoutQuantity = without(Object.keys(value), 'quantity');

      const newItem = pick(value, keysWithoutQuantity);
      const existsItem = pick(orderProduct, keysWithoutQuantity);

      return isEqual(newItem, existsItem);
    });

    if (existsOrderProduct) {
      existsOrderProduct.quantity += orderProduct.quantity;
    } else {
      products.push(orderProduct);
    }

    allProducts[que] = products;
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(allProducts));

    return allProducts;
  },

  update(que: number, orderProductIndex: number, orderProduct: OrderProductDto): CartResponseDto {
    const allProducts = this.fetchAll();
    const products = allProducts[que];
    products[orderProductIndex] = orderProduct;
    allProducts[que] = products;
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(allProducts));
    return allProducts;
  },

  remove(que: number, orderProductIndex: number): CartResponseDto {
    const allProducts = this.fetchAll();
    const products = allProducts[que];
    products.splice(orderProductIndex, 1);
    allProducts[que] = products;
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(allProducts));
    return allProducts;
  },

  clear() {
    const emptyState = [[]];
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(emptyState));
    return emptyState;
  },

  addQue() {
    const allProducts = this.fetchAll();
    allProducts[allProducts.length] = [];
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(allProducts));
    return allProducts;
  },

  clearQue(que: number) {
    const allProducts = this.fetchAll();
    allProducts[que] = [];
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(allProducts));
    return allProducts;
  },

  removeQue(que: number) {
    const allProducts = this.fetchAll();

    const newProducts = allProducts.length === 1 ? [[]] : allProducts.filter((product, index) => index !== que);
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(newProducts));
    return newProducts;
  },

  changeQuantity(que: number, orderProductIndex: number, quantity: number) {
    const allProducts = this.fetchAll();
    const products = allProducts[que];
    products[orderProductIndex].quantity = quantity;
    allProducts[que] = products;
    storageService.setItem(StorageKeysEnum.Products, JSON.stringify(allProducts));
    return allProducts;
  },
};
