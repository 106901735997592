import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
}

const AppContent: FC<Props> = ({ children }) => <Box component="main">{children}</Box>;

export default AppContent;
