import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';

import { themeSlice } from '@/store/slices/theme';
import { categoriesSlice } from '@/store/slices/categories';
import { authSlice } from '@/store/slices/auth';
import { productsSlice } from '@/store/slices/products';
import { cartSlice } from '@/store/slices/cart';
import { orderSlice } from '@/store/slices/order';
import { alertSlice } from '@/store/slices/alert';
import { printSlice } from '@/store/slices/print';
import { cartSelectedQueSlice } from '@/store/slices/cartSelectedQue';
import { removeTokens } from '@/helpers';
import { meSlice } from '@/store/slices/me';
import { deliveryInfoSlice } from '@/store/slices/deliveryInfo';
import { addressesSlice } from '@/store/slices/addresses';

const store = configureStore({
  reducer: {
    theme: themeSlice.reducer,
    categories: categoriesSlice.reducer,
    auth: authSlice.reducer,
    products: productsSlice.reducer,
    cart: cartSlice.reducer,
    cartSelectedQue: cartSelectedQueSlice.reducer,
    order: orderSlice.reducer,
    alert: alertSlice.reducer,
    print: printSlice.reducer,
    me: meSlice.reducer,
    deliveryInfo: deliveryInfoSlice.reducer,
    addresses: addressesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat((store) => (next) => async (action) => {
      if (isRejectedWithValue(action)) {
        if (action.payload?.response?.status === 401) {
          removeTokens();
          store.dispatch(authSlice.actions.signOut());
        }

        if (action.payload?.showAlert) {
          store.dispatch(
            alertSlice.actions.createAlert({
              type: 'error',
              message: action.payload.message,
            }),
          );
        }
      }

      return next(action);
    }),
});

export default store;
