import { FC, useState, MouseEvent } from 'react';

import Menu from '@mui/material/Menu';
import { Box, CircularProgress, MenuItem } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@mui/lab';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { printSlice, printThunk, selectFilteredPrinters, selectPrint, selectPrintLoading } from '@/store/slices/print';
import { OrderDto } from '@/store/slices/order/types';
import { alertSlice } from '@/store/slices/alert';
import { StoreLoadingEnum } from '@/store/types';
import { PrinterDto } from '@/store/slices/categories/types';

/**
 * @todo Create separate redux slice for printers
 * */

interface Props {
  printers: PrinterDto[];
  order: OrderDto;
}

const PrinterMenu: FC<Props> = ({ order, printers }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatch = useAppDispatch();
  const printLoading = useAppSelector(selectPrintLoading);
  const print = useAppSelector(selectPrint);
  const filteredPrinters = useAppSelector(selectFilteredPrinters(order, printers));

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = async (orderId: string, printerId: string) => {
    dispatch(printSlice.actions.setOrderId(orderId));

    const response = await dispatch(
      printThunk({
        orderId,
        params: {
          printer: printerId,
          printClientReceipt: printerId === 'handleClient',
        },
      }),
    );

    if (printThunk.fulfilled.match(response)) {
      if (response.payload.printed) {
        dispatch(
          alertSlice.actions.createAlert({
            type: 'success',
            message: 'Receipt successfully sent to printer',
          }),
        );
      }
    }
  };

  return (
    <Box>
      <LoadingButton
        disabled={!filteredPrinters.length}
        onClick={handleClick}
        loadingIndicator={<CircularProgress color="primary" size={20} />}
        loading={printLoading === StoreLoadingEnum.Pending && print?.orderId === order._id}
      >
        <PrintIcon />
      </LoadingButton>

      {!!filteredPrinters.length && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handleItemClick(order._id, 'handleClient')}>Client receipt</MenuItem>

          {filteredPrinters.map((printer) => (
            <MenuItem key={printer._id} onClick={() => handleItemClick(order._id, printer._id)}>
              {printer.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

export default PrinterMenu;
