import { FC } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { OrderFormInputs } from '../../types';

import { ProductDto } from '@/store/slices/products/types';

interface Props {
  open: boolean;
  onClose: () => void;
  product: ProductDto | null;
  onSubmit: (data: OrderFormInputs) => void;
}

const OrderDialog: FC<Props> = ({ open, onClose, product, onSubmit }) => {
  const { register, handleSubmit, reset } = useForm<OrderFormInputs>({
    defaultValues: {
      ingredients: [],
      ingredientGroup: [],
      comment: '',
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmitForm = (data: OrderFormInputs) => {
    reset();
    onSubmit(data);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="ingredient-dialog-title"
      aria-describedby="ingredient-dialog-description"
    >
      <DialogTitle id="ingredient-dialog-title">{product?.itemName}</DialogTitle>

      <DialogContent>
        {product && (
          <Box component="form" id="ingredients-form" onSubmit={handleSubmit(handleSubmitForm)} onReset={handleClose}>
            <Stack spacing={2}>
              <FormGroup>
                <FormControl>
                  <FormLabel id="ingredients-label">Comment</FormLabel>

                  <TextField fullWidth multiline rows={2} {...register('comment')} />
                </FormControl>
              </FormGroup>

              {!!product.ingredientGroups.length && (
                <FormGroup>
                  <FormControl>
                    <FormLabel id="ingredient-group-label">Product type</FormLabel>

                    <Paper variant="outlined" sx={{ p: 2 }}>
                      {product.ingredientGroups.map((ingredientGroup, index) => (
                        <FormGroup key={ingredientGroup._id}>
                          <FormControl variant="standard">
                            <FormLabel id={`ingredient-group-item-${index}-label`}>{ingredientGroup.name}</FormLabel>

                            <RadioGroup aria-labelledby="ingredient-group-label">
                              {ingredientGroup.ingredients.map((ingredient) => (
                                <Box
                                  key={ingredient._id}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FormControlLabel
                                    {...register(`ingredientGroup.${index}`)}
                                    control={<Radio />}
                                    label={ingredient.ingredientName}
                                    value={ingredient._id}
                                  />

                                  <Typography>{ingredient.price || 0} AMD</Typography>
                                </Box>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </FormGroup>
                      ))}
                    </Paper>
                  </FormControl>
                </FormGroup>
              )}

              {!!product.ingredients.length && (
                <FormGroup>
                  <FormControl>
                    <FormLabel id="ingredients-label">Ingredients</FormLabel>

                    {product.ingredients.map((ingredient) => (
                      <Box key={ingredient._id} display="flex" alignItems="center" justifyContent="space-between">
                        <FormControlLabel
                          key={ingredient.ingredientName}
                          {...register('ingredients')}
                          control={<Checkbox />}
                          label={ingredient.ingredientName}
                          value={ingredient._id}
                        />
                        <Typography>{ingredient.price || 0} AMD</Typography>
                      </Box>
                    ))}
                  </FormControl>
                </FormGroup>
              )}
            </Stack>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button type="reset" form="ingredients-form">
          Cancel
        </Button>

        <Button type="submit" form="ingredients-form" variant="contained" autoFocus>
          Add to cart
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDialog;
