import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { deliveryInfoApi } from './api';
import { DeliveryInfoRequest, DeliveryInfoResponse } from './types';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { createApiError } from '@/store/helpers';

interface DeliveryInfoState {
  entity: DeliveryInfoResponse | null;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: DeliveryInfoState = {
  entity: null,
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const fetchDeliveryInfoThunk = createAsyncThunk<DeliveryInfoResponse, DeliveryInfoRequest>(
  'deliveryInfo/fetch',
  async ({ destinations, orderPrice, isTakeAway }, thunkAPI) => {
    try {
      const response = await deliveryInfoApi.fetchAll(destinations, orderPrice, isTakeAway);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const deliveryInfoSlice = createSlice({
  name: 'deliveryInfo',
  initialState,
  reducers: {
    resetDeliveryPrice(state) {
      if (state.entity) {
        state.entity.totalPrice = state.entity.orderPrice;
        state.entity.deliveryPrice = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveryInfoThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entity = payload;
      })
      .addCase(fetchDeliveryInfoThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchDeliveryInfoThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      });
  },
});

export const selectDeliveryInfo = (state: RootState) => state.deliveryInfo.entity || null;
