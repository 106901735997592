import { Simulate } from 'react-dom/test-utils';

import error = Simulate.error;

export enum StorageKeysEnum {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  ExpiresIn = 'expiresIn',
  Products = 'products',
  ViewMode = 'viewMode',
}

export const storageService = {
  getItem(key: StorageKeysEnum) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.log(e);
    }
  },
  setItem(key: StorageKeysEnum, value: string) {
    if (typeof window !== undefined) {
      return localStorage.setItem(key, value);
    }

    throw error;
  },
  removeItem(key: StorageKeysEnum) {
    if (typeof window !== undefined) {
      return localStorage.removeItem(key);
    }

    throw error;
  },
  clear() {
    if (typeof window !== undefined) {
      return localStorage.clear();
    }

    throw error;
  },
};
