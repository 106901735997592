import { createSlice } from '@reduxjs/toolkit';

import { ThemeMode, ThemeModesEnum } from '@/store/slices/theme/types';
import { RootState } from '@/store/types';

const initialState: ThemeMode = {
  mode: ThemeModesEnum.Dark,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggle: (state) => {
      state.mode = state.mode === ThemeModesEnum.Dark ? ThemeModesEnum.Light : ThemeModesEnum.Dark;
    },
  },
});

export const selectThemeMode = (state: RootState) => state.theme.mode;
export const selectInvertedThemeMode = (state: RootState) =>
  state.theme.mode === ThemeModesEnum.Dark ? ThemeModesEnum.Light : ThemeModesEnum.Dark;
