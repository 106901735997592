import { FC, Fragment, SyntheticEvent, useEffect, useState } from 'react';

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Button,
  Tabs,
  Tab,
  Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import PaymentDialog from '../PaymentDialog';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import {
  addQueThunk,
  changeCartQuantityThunk,
  clearQueThunk,
  fetchCartProductsThunk,
  removeCartProductThunk,
  removeQueThunk,
  selectCartCount,
  selectCartProducts,
  updateCartProductThunk,
} from '@/store/slices/cart';
import { cartSelectedQueSlice, selectCartQue } from '@/store/slices/cartSelectedQue';
import { getPrice } from '@/helpers';
import OrderCommentDialog from '@/components/OrderCommentDialog';
import { OrderProductDto } from '@/store/slices/order/types';
import { HEADER_HEIGHT } from '@/constants';

const Summary: FC = () => {
  const dispatch = useAppDispatch();
  const cartQue = useAppSelector(selectCartQue);
  const orderProducts = useAppSelector(selectCartProducts);
  const cartCount = useAppSelector(selectCartCount);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<OrderProductDto | null>(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchCartProductsThunk());
  }, [dispatch]);

  const handleRemove = (que: number, orderProductIndex: number) => {
    dispatch(removeCartProductThunk({ que, orderProductIndex }));
  };

  const handlePay = () => {
    setPaymentDialogOpen(true);
  };

  const handleChangeQue = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== -1) {
      dispatch(cartSelectedQueSlice.actions.update(newValue));
    }
  };

  const handleAddQue = async () => {
    const response = await dispatch(addQueThunk());

    if (addQueThunk.fulfilled.match(response)) {
      await dispatch(cartSelectedQueSlice.actions.update(response.payload.length - 1));
    }
  };

  const handleCloseQue = async (que: number) => {
    await dispatch(removeQueThunk(que));
  };

  const handleClearQue = async (que: number) => {
    await dispatch(clearQueThunk(que));
  };

  const handleChangeQuantity = async (que: number, orderProductIndex: number, quantity: number, operator: 1 | -1) => {
    const newQuantity = quantity + operator;

    if (newQuantity > 0) {
      await dispatch(changeCartQuantityThunk({ que, orderProductIndex, quantity: newQuantity }));
    }
  };

  const handleCloseCommentDialog = () => {
    setSelectedProduct(null);
    setSelectedProductIndex(null);
  };

  const handleApplyComment = ({ comment }: { comment: string }) => {
    if (selectedProduct !== null && selectedProductIndex !== null) {
      dispatch(
        updateCartProductThunk({
          que: cartQue,
          orderProduct: { ...selectedProduct, comment },
          orderProductIndex: selectedProductIndex,
        }),
      );
    }
  };

  return (
    <>
      <Box
        component="header"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        }}
      >
        <Stack flexDirection="row" alignItems="center">
          <Tabs
            value={cartQue}
            onChange={handleChangeQue}
            variant="scrollable"
            scrollButtons={false}
            aria-label="basic tabs example"
            selectionFollowsFocus
          >
            {orderProducts.map((_, index) => (
              <Tab key={index} value={index} label={`Que ${index + 1}`} />
            ))}
          </Tabs>

          <IconButton color="primary" onClick={handleAddQue}>
            <AddIcon />
          </IconButton>
        </Stack>

        <TableContainer>
          <Table stickyHeader size="small" sx={{ overflow: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orderProducts[cartQue]?.map((orderProduct, index) => {
                const price = getPrice(orderProduct.item.price, orderProduct.ingredients);

                return (
                  <Fragment key={index}>
                    <TableRow sx={{ 'td, th': { borderBottom: 0 }, '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedProduct(orderProduct || null);
                          setSelectedProductIndex(index);
                        }}
                      >
                        <Typography>{orderProduct.item.itemName}</Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <IconButton
                            disabled={orderProduct.quantity === 1}
                            color="primary"
                            size="small"
                            onClick={() => handleChangeQuantity(cartQue, index, orderProduct.quantity, -1)}
                          >
                            <RemoveIcon />
                          </IconButton>

                          <Typography variant="inherit">{orderProduct.quantity}</Typography>

                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleChangeQuantity(cartQue, index, orderProduct.quantity, 1)}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </TableCell>

                      <TableCell align="right">{price}</TableCell>
                      <TableCell align="right">{price * orderProduct.quantity}</TableCell>
                      <TableCell align="right">
                        <IconButton size="small" aria-label="delete" onClick={() => handleRemove(cartQue, index)}>
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    <TableRow key={index}>
                      <TableCell colSpan={6} sx={{ borderTop: 0 }}>
                        {!!orderProduct.ingredients.length && (
                          <>
                            <Typography sx={{ fontWeight: 'bold' }}>Ingredients</Typography>

                            {orderProduct.ingredients.map((ingredient) => (
                              <Typography key={ingredient._id} variant="body2">
                                {ingredient.ingredientName} {ingredient.price || 0} AMD
                              </Typography>
                            ))}
                          </>
                        )}

                        {!!orderProduct.comment && (
                          <>
                            <Typography sx={{ fontWeight: 'bold' }}>Comment</Typography>
                            <Typography variant="body2">{orderProduct.comment}</Typography>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          component="footer"
          sx={{
            p: 2,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            marginTop: 'auto',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Button variant="contained" sx={{ mr: 1 }} disabled={!cartCount} onClick={handlePay}>
              Checkout
            </Button>

            <Box>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => handleClearQue(cartQue)}
                disabled={orderProducts[cartQue].length === 0}
              >
                Clear
              </Button>

              <Button variant="contained" onClick={() => handleCloseQue(cartQue)} disabled={orderProducts.length < 2}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <PaymentDialog open={paymentDialogOpen} onClose={() => setPaymentDialogOpen(false)} />

      <OrderCommentDialog
        orderProduct={selectedProduct}
        open={!!selectedProduct}
        onClose={handleCloseCommentDialog}
        onSubmit={handleApplyComment}
      />
    </>
  );
};

export default Summary;
