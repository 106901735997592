import React, { FC } from 'react';

import '@/assets/styles/index.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppProvider from '@/components/AppProvider';
import AppRoutes from '@/routes';

import './App.scss';

const App: FC = () => (
  <AppProvider>
    <AppRoutes />
  </AppProvider>
);

export default App;
