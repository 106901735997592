import { AxiosResponse } from 'axios';

import { DeliveryInfoResponse } from '@/store/slices/deliveryInfo/types';
import apiService from '@/services/api';

export const deliveryInfoApi = {
  async fetchAll(
    destinations: string,
    orderPrice: number,
    isTakeAway: boolean,
  ): Promise<AxiosResponse<DeliveryInfoResponse>> {
    return apiService.v1.get('/v1/address/info', {
      params: {
        destinations: destinations || '',
        orderPrice,
        isTakeAway,
      },
    });
  },
};
