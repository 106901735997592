import { FC } from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { AuthDto } from '@/store/slices/auth/types';
import { useAppDispatch } from '@/hooks/store';
import { signInThunk } from '@/store/slices/auth';

const SignIn: FC = () => {
  const dispatch = useAppDispatch();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data: AuthDto) => {
    dispatch(signInThunk(data));
  };

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      sx={{
        mt: 10,
        p: 2,
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography component="h1" variant="h5">
        Sign in
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email Address"
          autoComplete="email"
          autoFocus
          {...register('email')}
        />

        <TextField
          margin="normal"
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          {...register('password')}
        />

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
      </Box>
    </Stack>
  );
};

export default SignIn;
