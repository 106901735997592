import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '../../types';

import { AlertData } from '@/store/slices/alert/types';

interface AlertState {
  entity: AlertData | null;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: AlertState = {
  entity: null,
  loading: StoreLoadingEnum.Idle,
  error: null,
};

/**
 * Slice
 */

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.entity = {
        type: action.payload.type,
        message: action.payload.message,
      };
    },
    deleteAlert: (state) => {
      state.entity = null;
    },
  },
});

/**
 * Selectors
 */

export const selectAlert = (state: RootState) => state.alert.entity;
