import { FC, useEffect, useRef } from 'react';

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { debounce } from 'lodash';
import { LoadingButton } from '@mui/lab';

import Transition from './Transition';
import PaymentFormFields from './PaymentFormFields';
import PaymentPricesInfo from './PaymentPricesInfo';
import PaymentKeypad from './PaymentKeypad';

import { PaymentMethodsEnum } from '@/types';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { removeQueThunk, selectCartCount, selectCartPrice, selectCartProducts } from '@/store/slices/cart';
import { PaymentFormInputs } from '@/routes/Categories/types';
import { OrderMethodsEnum } from '@/store/slices/order/types';
import { createOrderThunk, selectOrdersLoading } from '@/store/slices/order';
import { selectCartQue } from '@/store/slices/cartSelectedQue';
import { fetchDeliveryInfoThunk, selectDeliveryInfo, deliveryInfoSlice } from '@/store/slices/deliveryInfo';
import { StoreLoadingEnum } from '@/store/types';

interface Props {
  open: boolean;
  onClose: () => void;
}

const PaymentDialog: FC<Props> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();

  const cartQue = useAppSelector(selectCartQue);
  const cartProducts = useAppSelector(selectCartProducts);
  const cartPrice = useAppSelector(selectCartPrice);
  const cartCount = useAppSelector(selectCartCount);
  const deliveryInfo = useAppSelector(selectDeliveryInfo);
  const ordersLoading = useAppSelector(selectOrdersLoading);

  const formMethods = useForm<PaymentFormInputs>({
    defaultValues: {
      amount: 0,
      excludeDeliveryPrice: false,
      paymentMethod: PaymentMethodsEnum.Cash,
      orderMethod: OrderMethodsEnum.DineIn,
      address: '',
      entrance: '',
      floor: '',
      apartment: '',
      description: '',
      phoneNumber: '',
    },
  });

  const {
    setValue,
    setFocus,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting },
  } = formMethods;

  useEffect(() => {
    if (open) {
      setValue('amount', deliveryInfo?.totalPrice || 0);
    }
  }, [deliveryInfo?.totalPrice, open, setValue]);

  const orderMethod = useWatch({
    control,
    name: 'orderMethod',
  });

  const address = useWatch({
    control,
    name: 'address',
  });

  const amount = useWatch({
    control,
    name: 'amount',
  });

  const excludeDeliveryPrice = useWatch({
    control,
    name: 'excludeDeliveryPrice',
  });

  const getPricesDebouncedRef = useRef(
    debounce((address: string, cartPrice: number, orderMethod: OrderMethodsEnum) => {
      dispatch(
        fetchDeliveryInfoThunk({
          destinations: address,
          orderPrice: cartPrice,
          isTakeAway: orderMethod !== OrderMethodsEnum.CallDelivery,
        }),
      );
    }, 500),
  );

  useEffect(() => {
    if (open && (orderMethod !== OrderMethodsEnum.CallDelivery || address)) {
      if (excludeDeliveryPrice) {
        dispatch(deliveryInfoSlice.actions.resetDeliveryPrice());
      } else {
        getPricesDebouncedRef.current(address, cartPrice, orderMethod);
      }
    }
  }, [open, orderMethod, address, cartPrice, excludeDeliveryPrice, dispatch]);

  const handleOnPadClick = (item: string) => {
    setFocus('amount');
    const amount = getValues('amount');

    if (item === '⌫') {
      const amountStr = `${amount}`;
      setValue('amount', Number(amountStr.substring(0, amountStr.length - 1)));
      return;
    }

    setValue('amount', Number(`${amount}${item}`));
  };

  const onSubmit = async (data: PaymentFormInputs) => {
    const orderData: any = {
      orderItems: cartProducts[cartQue].map((cartProduct) => ({
        item: cartProduct.item._id,
        quantity: cartProduct.quantity,
        // TODO Fix null problem with ingredient radio buttons and remove filter checking here
        ingredients: cartProduct.ingredients.filter((ingredient) => !!ingredient) || [],
        comment: cartProduct.comment,
      })),
      paymentMethod: data.paymentMethod,
      excludeDeliveryPrice,
      address: data.address,
      entrance: data.entrance,
      floor: data.floor,
      apartment: data.apartment,
      phoneNumber: data.phoneNumber,
      description: data.description,
      orderMethod: data.orderMethod,
    };

    const response = await dispatch(createOrderThunk(orderData));

    if (createOrderThunk.fulfilled.match(response)) {
      await dispatch(removeQueThunk(cartQue));
      onClose();
      reset();
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar variant="outlined" color="transparent" sx={{ position: 'relative' }} elevation={0}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Payment
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent dividers sx={{ p: 0 }}>
        <Grid container height="100%">
          <Grid item xs={12} md={6} sx={{ height: '100%' }}>
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
                flexDirection: 'column',
              }}
            >
              <Stack spacing={1} sx={{ p: 2, height: '100%' }}>
                {orderMethod === OrderMethodsEnum.CallDelivery && !address ? (
                  <Typography>Please fill in the address to calculate the prices</Typography>
                ) : (
                  <PaymentPricesInfo amount={amount} deliveryInfo={deliveryInfo} cartPrice={cartPrice} />
                )}

                <FormProvider {...formMethods}>
                  <Box component="form" id="payment-form" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <PaymentFormFields />
                  </Box>
                </FormProvider>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ backgroundColor: grey[200], display: { xs: 'none', md: 'block' } }}>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', p: 2 }}>
              <PaymentKeypad onClick={handleOnPadClick} />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2, justifyContent: 'flex-start' }}>
        <LoadingButton
          loading={isSubmitting || ordersLoading === StoreLoadingEnum.Pending}
          variant="contained"
          type="submit"
          form="payment-form"
          disabled={!cartCount || isSubmitting || ordersLoading === StoreLoadingEnum.Pending}
        >
          Pay and print
        </LoadingButton>

        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
