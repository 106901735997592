import { FC } from 'react';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AppLayout from '@/components/AppLayout';
import SignIn from '@/routes/SignIn';
import ProtectedRoutes from '@/components/ProtectedRoutes';
import UnProtectedRoutes from '@/components/UnProtectedRoutes';
import Categories from '@/routes/Categories';
import History from '@/routes/History';

const AppRoutes: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<AppLayout />}>
        <Route element={<ProtectedRoutes />}>
          <Route path="categories/*" element={<Categories />} />
          <Route path="history/*" element={<History />} />
        </Route>

        <Route element={<UnProtectedRoutes />}>
          <Route path="login" element={<SignIn />} />
        </Route>

        <Route path="/*" element={<Navigate to="categories" />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
