import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { ProductsRequestDto, ProductsResponseDto } from '@/store/slices/products/types';
import { productsApi } from '@/store/slices/products/api';
import { createApiError } from '@/store/helpers';

interface ProductsState {
  entities: ProductsResponseDto | null;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: ProductsState = {
  entities: [],
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const fetchProductsThunk = createAsyncThunk<ProductsResponseDto, ProductsRequestDto>(
  'products/fetch',
  async (params, thunkAPI) => {
    try {
      const response = await productsApi.fetchAll(params);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    reset(state) {
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(fetchProductsThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchProductsThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      });
  },
});

export const selectProducts = (state: RootState) => state.products.entities || [];
