import { FC, useEffect } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Stack,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { OrderProductDto } from '@/store/slices/order/types';

interface OrderComment {
  comment: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  orderProduct: OrderProductDto | null;
  onSubmit: (data: OrderComment) => void;
}

const OrderCommentDialog: FC<Props> = ({ open, onClose, orderProduct, onSubmit }) => {
  const { register, handleSubmit, setValue, reset } = useForm<OrderComment>({
    defaultValues: {
      comment: orderProduct?.comment || '',
    },
  });

  useEffect(() => {
    setValue('comment', orderProduct?.comment || '');
  }, [orderProduct, setValue]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmitForm = (data: OrderComment) => {
    reset();
    onSubmit(data);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="ingredient-dialog-title"
      aria-describedby="ingredient-dialog-description"
    >
      <DialogTitle id="ingredient-dialog-title">{orderProduct?.item?.itemName}</DialogTitle>

      <DialogContent>
        {orderProduct && (
          <Box component="form" id="ingredients-form" onSubmit={handleSubmit(handleSubmitForm)} onReset={handleClose}>
            <Stack spacing={2}>
              <FormGroup>
                <FormControl>
                  <FormLabel id="ingredients-label">Comment</FormLabel>

                  <TextField fullWidth multiline rows={2} {...register('comment')} />
                </FormControl>
              </FormGroup>
            </Stack>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button type="reset" form="ingredients-form">
          Cancel
        </Button>

        <Button type="submit" form="ingredients-form" variant="contained" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderCommentDialog;
