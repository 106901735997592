import * as React from 'react';

import Toolbar from '@mui/material/Toolbar';
import { AppBar, Badge, Dialog, Grid, IconButton, SpeedDial, Typography } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import Summary from '@/routes/Categories/components/Summary';
import CategoriesList from '@/routes/Categories/routes/CategoriesList';
import ProductsList from '@/routes/Categories/routes/ProductsList';
import AppFullScreenModalTransition from '@/components/AppFullScreenModalTransition';
import { useAppSelector } from '@/hooks/store';
import { selectCartCount } from '@/store/slices/cart';

export default function ResponsiveDrawer() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const cartCount = useAppSelector(selectCartCount);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <SpeedDial
        ariaLabel="cart bottom"
        sx={{ display: { xs: 'block', md: 'none' }, position: 'fixed', bottom: 10, right: 10 }}
        icon={
          cartCount ? (
            <Badge badgeContent={cartCount} color="error" sx={{ '> .MuiBadge-badge': { top: -10, right: -10 } }}>
              <ShoppingCartIcon />
            </Badge>
          ) : (
            <ShoppingCartIcon />
          )
        }
        onClick={() => setMobileOpen(true)}
      />

      <Grid container>
        <Grid display={{ xs: 'none', md: 'block' }} item xs={12} md={5} lg={4}>
          <Summary />
        </Grid>

        <Grid item xs={12} md={7} lg={8}>
          <Routes>
            <Route index path="/" element={<CategoriesList />} />
            <Route path="/:categoryId" element={<ProductsList />} />
          </Routes>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        TransitionComponent={AppFullScreenModalTransition}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <AppBar variant="outlined" color="transparent" sx={{ position: 'relative' }} elevation={0}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleDrawerToggle} aria-label="close">
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Cart
            </Typography>
          </Toolbar>
        </AppBar>

        <Summary />
      </Dialog>
    </>
  );
}
