import { FC, useEffect } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { selectIsLoggedIn } from '@/store/slices/auth';
import { fetchMeThunk } from '@/store/slices/me';

const ProtectedRoutes: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    dispatch(fetchMeThunk());
  }, [dispatch]);

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
