import axios from 'axios';

import { axiosOnFulfilled, axiosOnRejected } from '@/services/api/helpers';

const apiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    restaurant: process.env.CLIENT_ID || '',
  },
});

const authInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/auth`,
});

apiInstance.interceptors.request.use(axiosOnFulfilled, axiosOnRejected);

const apiService = {
  v1: apiInstance,
  auth: authInstance,
};

export default apiService;
