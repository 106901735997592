import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/system';
import { Provider as StoreProvider } from 'react-redux';

import theme from '@/theme';
import store from '@/store';
import AppAlertProvider from '@/components/AppAlertProvider';

interface Props {
  children?: ReactNode;
}

const AppProvider: FC<Props> = ({ children }) => (
  <StoreProvider store={store}>
    <AppAlertProvider>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppAlertProvider>
  </StoreProvider>
);

export default AppProvider;
