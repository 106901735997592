import { AxiosResponse } from 'axios';

import { PrinterDto } from '../categories/types';

import apiService from '@/services/api';
import { PrintRequest, PrintResponse } from '@/store/slices/print/types';

export const printApi = {
  async print(orderId: string, body: PrintRequest): Promise<AxiosResponse<PrintResponse>> {
    return apiService.v1.post(`/print/${orderId}`, body);
  },
};

export const printersApi = {
  async fetchAll(params: any): Promise<AxiosResponse<PrinterDto[]>> {
    return apiService.v1.get('/printers', {
      params,
    });
  },
};
