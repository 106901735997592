import { createAsyncThunk, createEntityAdapter, createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { CreateOrderResponse, OrderDto, OrderResponse, OrderRequest } from '@/store/slices/order/types';
import { orderApi } from '@/store/slices/order/api';
import { createApiError } from '@/store/helpers';
import { alertSlice } from '@/store/slices/alert';

interface OrderState {
  loading: StoreLoadingEnum;
  total: number;
  error: SerializedError | null;
}

export const fetchOrdersThunk = createAsyncThunk<OrderResponse, OrderRequest>(
  'order/fetchAll',
  async (params, thunkAPI) => {
    try {
      const { data } = await orderApi.fetchAll(params);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const createOrderThunk = createAsyncThunk<CreateOrderResponse, OrderDto>(
  'order/create',
  async (orderData, thunkAPI) => {
    try {
      const response = await orderApi.create(orderData);
      await thunkAPI.dispatch(
        alertSlice.actions.createAlert({
          type: 'success',
          message: 'Order created successfully',
        }),
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const ordersAdapter = createEntityAdapter<OrderDto>({
  selectId: (order) => order._id,
});

const initialState = ordersAdapter.getInitialState<OrderState>({
  loading: StoreLoadingEnum.Idle,
  total: 0,
  error: null,
});

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    reset(state) {
      ordersAdapter.setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersThunk.fulfilled, (state, action) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.total = action.payload.total;
        ordersAdapter.addMany(state, action.payload.result);
      })
      .addCase(fetchOrdersThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchOrdersThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(createOrderThunk.fulfilled, (state) => {
        state.loading = StoreLoadingEnum.Succeeded;
      })
      .addCase(createOrderThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(createOrderThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      });
  },
});

export const selectOrdersLoading = (state: RootState) => state.order.loading;
export const ordersTotal = (state: RootState) => state.order.total;

export const {
  selectById: selectOrderById,
  selectIds: selectOrderIds,
  selectEntities: selectOrderEntities,
  selectAll: selectAllOrders,
  selectTotal: selectTotalOrders,
} = ordersAdapter.getSelectors<RootState>((state) => state.order);
