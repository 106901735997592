import { TokensDto } from '@/types/api';
import { StorageKeysEnum, storageService } from '@/services/storage';
import { IngredientDto } from '@/store/slices/products/types';

export const storeTokens = ({ accessToken, refreshToken, expiresIn }: TokensDto) => {
  storageService.setItem(StorageKeysEnum.AccessToken, accessToken);
  storageService.setItem(StorageKeysEnum.RefreshToken, refreshToken);
  storageService.setItem(StorageKeysEnum.ExpiresIn, String(Date.now() + Number(expiresIn)));
};

export const removeTokens = () => {
  storageService.removeItem(StorageKeysEnum.AccessToken);
  storageService.removeItem(StorageKeysEnum.RefreshToken);
  storageService.removeItem(StorageKeysEnum.ExpiresIn);
};

export const getPrice = (price: number, ingredients: IngredientDto[]) => {
  const ingredientsPrice = ingredients.reduce((acc, ingredient) => acc + (ingredient?.price || 0), 0);
  return price + ingredientsPrice;
};

export const numberFormat = (value: number | bigint): string => {
  const PercentFormat = new Intl.NumberFormat('zh-Hans-CN', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return PercentFormat.format(value);
};
