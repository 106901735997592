import { FC, useState } from 'react';

import Typography from '@mui/material/Typography';
import { Box, Card, CardActions, CardContent, Stack } from '@mui/material';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import PrinterMenu from './PrinterMenu';

import { OrderDto } from '@/store/slices/order/types';
import {
  EntryPointMapping,
  OrderStatusMapping,
  orderMethodMapping,
  paymentMethodMapping,
  paymentStatusMapping,
} from '@/constants/paymnet';
import OrderListItemCollapse from '@/routes/History/components/OrdersList/OrderListItem/OrderListItemCollapse';
import { PrinterDto } from '@/store/slices/categories/types';

interface Props {
  printers: PrinterDto[];
  order: OrderDto;
}

const OrdersListItem: FC<Props> = ({ order, printers }) => {
  const [open, setOpen] = useState(false);

  return (
    <Card variant={open ? 'elevation' : 'outlined'} elevation={5 * Number(open)}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Order ID
          </Typography>

          <Typography variant="body1">#{`${order?.orderId}`.slice(-3)}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Date:
          </Typography>

          <Typography variant="body1">{format(new Date(order.createdAt), 'dd MMM yyyy k:mm')}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Total Price:
          </Typography>

          <Typography variant="body1">
            {order.orderItems.reduce((acc, orderProduct) => acc + orderProduct.item.price * orderProduct.quantity, 0)}{' '}
            AMD
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Payment Method:
          </Typography>

          <Typography variant="body1">{paymentMethodMapping[order.payment.paymentMethod].title}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Payment Status:
          </Typography>

          <Typography variant="body1">{paymentStatusMapping[order.payment.status].title}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Order Status:
          </Typography>

          <Typography variant="body1">{OrderStatusMapping[order.status].title}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Entry Point:
          </Typography>

          <Typography variant="body1">{EntryPointMapping[order.entryPoint].title}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Order Method:
          </Typography>

          <Typography variant="body1">{orderMethodMapping[order.orderMethod].title}</Typography>
        </Stack>
      </CardContent>

      <CardActions>
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
          <PrinterMenu order={order} printers={printers} />
        </Box>

        <IconButton style={{ marginLeft: 'auto' }} aria-label="expand" onClick={() => setOpen((value) => !value)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </CardActions>

      <OrderListItemCollapse orderProducts={order.orderItems} open={open} />
    </Card>
  );
};

export default OrdersListItem;
