import { IngredientDto, ProductDto } from '@/store/slices/products/types';
import { PaymentMethodsEnum } from '@/types';
import { PaginationRequest } from '@/types/api';

export enum OrderMethodsEnum {
  DineIn = 'DINE_IN',
  Glovo = 'GLOVO',
  TakeAway = 'TAKE_AWAY',
  CallDelivery = 'CALL_DELIVERY',
  AppDelivery = 'APPLICATION_DELIVERY',
}

export enum PaymentStatusEnum {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Fail = 'FAIL',
}

export enum OrderStatusEnum {
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Cancelled = 'CANCELLED',
}

export enum EntryPointEnum {
  Pos = 'POS',
  Application = 'APPLICATION',
}

export interface OrderProductDto {
  _id?: string;
  item: ProductDto;
  quantity: number;
  ingredients: IngredientDto[];
  comment: string;
}

export interface OrderDto {
  _id: string;
  orderItems: OrderProductDto[];
  consumer: string;
  payment: {
    paymentMethod: PaymentMethodsEnum;
    status: PaymentStatusEnum;
  };
  address: string;
  status: OrderStatusEnum;
  entryPoint: EntryPointEnum;
  excludeDeliveryPrice?: boolean;
  entrance?: string;
  floor?: string;
  apartment?: string;
  description?: string;
  phoneNumber: string;
  discountPercent?: number;
  deliveryPrice?: number;
  orderMethod: OrderMethodsEnum;
  orderId?: number;
  createdAt: string;
}

export interface CreateOrderResponse {
  _id: string;
  orderId: number;
  success: boolean;
  totalPrice: number;
}

export interface OrderResponse {
  result: OrderDto[];
  total: number;
}
export interface OrderRequest extends PaginationRequest {
  search?: string;
  createdAt?: {
    $gte?: string;
    $lte?: string;
  };
}
