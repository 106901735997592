import { FC, memo, SyntheticEvent, useCallback, useEffect, useRef } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Box, debounce } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { addressesSlice, fetchAddressesThunk, selectAddresses } from '@/store/slices/addresses';
import { AddressDto } from '@/store/slices/addresses/types';

export type AutocompletePhoneNumberProps = Omit<
  AutocompleteProps<AddressDto, undefined, true, true>,
  'options' | 'renderInput' | 'onChange' | 'onInputChange'
> & {
  onChange?: (address: AddressDto) => void;
  onInputChange?: (value: string) => void;
};

const AutocompletePhoneNumber: FC<AutocompletePhoneNumberProps> = ({ onChange, onInputChange, ...props }) => {
  const addresses = useAppSelector(selectAddresses);
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(addressesSlice.actions.reset());
    },
    [dispatch],
  );

  const handleOnChange = useCallback(
    (e: SyntheticEvent, address: string | AddressDto) => {
      if (address && typeof address === 'object') {
        onChange?.(address);
      }
    },
    [onChange],
  );

  const debouncedFetchThunk = useRef(
    debounce((search: string) => {
      dispatch(fetchAddressesThunk({ search }));
    }, 300),
  ).current;

  const handleOnInputChange = useCallback(
    (e: SyntheticEvent, value: string, reason: string) => {
      onInputChange?.(value);
      const search = value.replace('+', '');
      if (reason === 'input' && search) debouncedFetchThunk(search);
    },
    [debouncedFetchThunk, onInputChange],
  );

  return (
    <Autocomplete
      {...props}
      options={addresses}
      onChange={handleOnChange}
      freeSolo
      onInputChange={handleOnInputChange}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.phoneNumber)}
      renderInput={(params) => <TextField {...params} margin="dense" label="Phone Number" />}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props} key={option._id}>
          {`${option.phoneNumber} ${option.address}`}
        </Box>
      )}
    />
  );
};

export default memo(AutocompletePhoneNumber);
