import { forwardRef } from 'react';

import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';
import './styles.scss';
import { useTheme } from '@mui/material';

const AppNavLink = forwardRef<HTMLAnchorElement, RouterNavLinkProps>(({ style, ...props }, ref) => {
  const theme = useTheme();

  return (
    <RouterNavLink
      ref={ref}
      style={({ isActive }) => ({
        textDecoration: 'underline',
        ...style,
        ...(isActive && { color: theme.palette.primary.main }),
      })}
      {...props}
    />
  );
});

AppNavLink.displayName = 'routerNavLink';

export default AppNavLink;
