import { useCallback, useEffect, useState } from 'react';

import { Box, CircularProgress, Grid } from '@mui/material';
import { format } from 'date-fns';
import { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroller';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import {
  fetchOrdersThunk,
  orderSlice,
  ordersTotal,
  selectAllOrders,
  selectOrdersLoading,
  selectTotalOrders,
} from '@/store/slices/order';
import OrdersListItem from '@/routes/History/components/OrdersList/OrderListItem';
import { selectMe } from '@/store/slices/me';
import { WorkingHours } from '@/store/slices/me/types';
import { PrinterDto } from '@/store/slices/categories/types';
import { printersApi } from '@/store/slices/print/api';
import { StoreLoadingEnum } from '@/store/types';

const OrdersList = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector(selectAllOrders);
  const ordersLoadedLength = useAppSelector(selectTotalOrders);
  const ordersTotalCount = useAppSelector(ordersTotal);
  const isLoading = useAppSelector(selectOrdersLoading);
  const profile = useAppSelector(selectMe);
  const [page, setPage] = useState(0);

  const [printers, setPrinters] = useState<PrinterDto[]>([]);
  useEffect(() => {
    printersApi.fetchAll({ deleted: false, isActive: true }).then((response: AxiosResponse<PrinterDto[]>) => {
      setPrinters(response.data);
    });
  }, []);

  useEffect(
    () => () => {
      dispatch(orderSlice.actions.reset());
    },
    [dispatch],
  );

  const getOrders = useCallback(
    async (page = 0) => {
      const weekDay = format(new Date(), 'EEEE') as keyof WorkingHours;
      const now = new Date();
      if (profile) {
        const { openTime, closeTime } = profile.settings.workingHours[weekDay];
        const openDateTime = new Date(`${format(new Date(), 'yyyy-MM-dd')}T${openTime}`);
        const closeDateTime = new Date(`${format(new Date(), 'yyyy-MM-dd')}T${closeTime}`);
        if (now < openDateTime || now < closeDateTime) {
          openDateTime.setDate(openDateTime.getDate() - 1);
        }
        const $gte = openDateTime.toISOString();

        dispatch(fetchOrdersThunk({ createdAt: { $gte }, rowsPerPage: 15, page }));
      }
    },
    [dispatch, profile],
  );

  useEffect(() => {
    getOrders();
  }, [dispatch, getOrders, profile]);

  const handleLoadMore = useCallback(() => {
    if (isLoading !== StoreLoadingEnum.Pending) {
      getOrders(page + 1);
      setPage((page) => page + 1);
    }
  }, [getOrders, isLoading, page]);

  return (
    <Box>
      <InfiniteScroll
        pageStart={0}
        threshold={400}
        loadMore={handleLoadMore}
        hasMore={ordersLoadedLength !== ordersTotalCount}
        loader={<CircularProgress key={1} sx={{ mt: 2, mb: 2, ml: '50%' }} />}
      >
        <Grid container spacing={1}>
          {orders.map((order) => (
            <Grid item xs={12} md={4} lg={3} key={order._id}>
              <OrdersListItem order={order} printers={printers} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Box>
  );
};

export default OrdersList;
