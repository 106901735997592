import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { CategoriesResponseDto } from '@/store/slices/categories/types';
import { categoriesApi } from '@/store/slices/categories/api';
import { createApiError } from '@/store/helpers';

interface CategoriesState {
  entities: CategoriesResponseDto | null;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: CategoriesState = {
  entities: [],
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const fetchCategoriesThunk = createAsyncThunk<CategoriesResponseDto, void>(
  'categories/fetch',
  async (params, thunkAPI) => {
    try {
      const response = await categoriesApi.fetchAll();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(fetchCategoriesThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchCategoriesThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      });
  },
});

export const selectCategories = (state: RootState) => state.categories.entities || [];
export const selectCategory = (categoryId?: string) => (state: RootState) =>
  state.categories.entities?.find((category) => category._id === categoryId);
