import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import {
  AddToCartRequest,
  CartResponseDto,
  ChangeCartProductQuantityRequest,
  RemoveCartProductRequest,
  UpdateCartProductRequest,
} from '@/store/slices/cart/types';
import { RootState, StoreLoadingEnum } from '@/store/types';
import { cartApi } from '@/store/slices/cart/api';
import { createApiError } from '@/store/helpers';
import { cartSelectedQueSlice } from '@/store/slices/cartSelectedQue';
import { getPrice } from '@/helpers';

interface CartState {
  entities: CartResponseDto;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: CartState = {
  entities: [[]],
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const fetchCartProductsThunk = createAsyncThunk<CartResponseDto>('cart/fetchAll', async (params, thunkAPI) => {
  try {
    return cartApi.fetchAll();
  } catch (e) {
    return thunkAPI.rejectWithValue(createApiError(e));
  }
});

export const addToCartThunk = createAsyncThunk<CartResponseDto, AddToCartRequest>(
  'cart/add',
  async (params, thunkAPI) => {
    try {
      return cartApi.add(params.que, params.orderProduct);
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const updateCartProductThunk = createAsyncThunk<CartResponseDto, UpdateCartProductRequest>(
  'cart/update',
  async ({ que, orderProductIndex, orderProduct }, thunkAPI) => {
    try {
      return cartApi.update(que, orderProductIndex, orderProduct);
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const removeCartProductThunk = createAsyncThunk<CartResponseDto, RemoveCartProductRequest>(
  'cart/remove',
  async ({ que, orderProductIndex }, thunkAPI) => {
    try {
      return cartApi.remove(que, orderProductIndex);
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const clearCartThunk = createAsyncThunk<CartResponseDto>('cart/clear', async (params, thunkAPI) => {
  try {
    return cartApi.clear();
  } catch (e) {
    return thunkAPI.rejectWithValue(createApiError(e));
  }
});

export const addQueThunk = createAsyncThunk<CartResponseDto, void>('cart/addQue', async (params, thunkAPI) => {
  try {
    return cartApi.addQue();
  } catch (e) {
    return thunkAPI.rejectWithValue(createApiError(e));
  }
});

export const removeQueThunk = createAsyncThunk<CartResponseDto, number>('cart/removeQue', async (que, thunkAPI) => {
  try {
    const data = cartApi.removeQue(que);
    thunkAPI.dispatch(cartSelectedQueSlice.actions.update(que === 0 ? que : que - 1));
    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(createApiError(e));
  }
});

export const clearQueThunk = createAsyncThunk<CartResponseDto, number>('cart/clearQue', async (que, thunkAPI) => {
  try {
    return cartApi.clearQue(que);
  } catch (e) {
    return thunkAPI.rejectWithValue(createApiError(e));
  }
});

export const changeCartQuantityThunk = createAsyncThunk<CartResponseDto, ChangeCartProductQuantityRequest>(
  'cart/changeQuantity',
  async ({ que, orderProductIndex, quantity }, thunkAPI) => {
    try {
      return cartApi.changeQuantity(que, orderProductIndex, quantity);
    } catch (e) {
      return thunkAPI.rejectWithValue(createApiError(e));
    }
  },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartProductsThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(fetchCartProductsThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(fetchCartProductsThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(addToCartThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(addToCartThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(addToCartThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(updateCartProductThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(updateCartProductThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(updateCartProductThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(removeCartProductThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(removeCartProductThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(removeCartProductThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(clearCartThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(clearCartThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(clearCartThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(addQueThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(addQueThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(addQueThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(clearQueThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(clearQueThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(clearQueThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(removeQueThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(removeQueThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(removeQueThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      })

      .addCase(changeCartQuantityThunk.fulfilled, (state, { payload }) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entities = payload;
      })
      .addCase(changeCartQuantityThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(changeCartQuantityThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.error = error;
      });
  },
});

export const selectCartProducts = (state: RootState) => state.cart.entities;

export const selectCartCount = (state: RootState) => {
  const cartProducts = state.cart.entities[state.cartSelectedQue.entity];
  if (cartProducts) {
    return state.cart.entities[state.cartSelectedQue.entity].reduce(
      (acc, orderProduct) => acc + orderProduct.quantity,
      0,
    );
  }

  return 0;
};
export const selectCartPrice = (state: RootState) => {
  const cartProducts = state.cart.entities[state.cartSelectedQue.entity];
  if (cartProducts) {
    return cartProducts.reduce(
      (acc, orderProduct) => acc + getPrice(orderProduct.item.price, orderProduct.ingredients) * orderProduct.quantity,
      0,
    );
  }

  return 0;
};
