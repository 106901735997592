import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { RootState, StoreLoadingEnum } from '@/store/types';
import { PrintRequestDto, PrintResponse } from '@/store/slices/print/types';
import { printApi } from '@/store/slices/print/api';
import { PrinterDto } from '@/store/slices/categories/types';
import { OrderDto, OrderMethodsEnum } from '@/store/slices/order/types';

interface PrintState {
  entity: PrintResponse | null;
  loading: StoreLoadingEnum;
  error: SerializedError | null;
}

const initialState: PrintState = {
  entity: null,
  loading: StoreLoadingEnum.Idle,
  error: null,
};

export const printThunk = createAsyncThunk<PrintResponse, PrintRequestDto>(
  'print/receipt',
  async (request, thunkAPI) => {
    try {
      const { data } = await printApi.print(request.orderId, request.params);
      await thunkAPI.dispatch(printSlice.actions.reset());
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    setOrderId(state, action) {
      state.entity = {
        printed: false,
        orderId: action.payload,
      };
    },
    reset(state) {
      state.entity = null;
      state.loading = StoreLoadingEnum.Idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(printThunk.fulfilled, (state, action) => {
        state.loading = StoreLoadingEnum.Succeeded;
        state.entity = action.payload;
      })
      .addCase(printThunk.pending, (state) => {
        state.loading = StoreLoadingEnum.Pending;
      })
      .addCase(printThunk.rejected, (state, { error }) => {
        state.loading = StoreLoadingEnum.Failed;
        state.entity = null;
        state.error = error;
      });
  },
});

export const selectPrint = (state: RootState) => state.print.entity;
export const selectPrintLoading = (state: RootState) => state.print.loading;
export const selectFilteredPrinters = (order: OrderDto, printers: PrinterDto[]) => (/*state: RootState*/) =>
  printers.reduce<PrinterDto[]>((acc, printer) => {
    const isInPrinter = order.orderItems.find((orderItem) =>
      printer.categories.find((category) => category._id === orderItem.item.category._id),
    );

    if (isInPrinter && printer.isDineIn === (order.orderMethod === OrderMethodsEnum.DineIn)) {
      return [...acc, printer];
    }

    return acc;
  }, []);
