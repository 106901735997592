import { FC, Fragment } from 'react';

import Collapse from '@mui/material/Collapse';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { OrderProductDto } from '@/store/slices/order/types';

interface Props {
  orderProducts?: OrderProductDto[];
  open: boolean;
}

const OrderListItemCollapse: FC<Props> = ({ orderProducts, open }) => (
  <Collapse in={open} timeout="auto" unmountOnExit>
    <Stack spacing={1}>
      <List sx={{ bgcolor: 'background.paper' }}>
        {orderProducts?.map((orderProduct) => {
          const ingredients = orderProduct.ingredients.map((ingredient) => ingredient.ingredientName).join(', ');
          return (
            <Fragment key={orderProduct._id}>
              <Divider />
              <ListItem secondaryAction={orderProduct.quantity}>
                <ListItemAvatar>
                  <Avatar src={`${process.env.REACT_APP_STORAGE_URL}/${orderProduct.item.image}`} />
                </ListItemAvatar>

                <Tooltip title={ingredients}>
                  <ListItemText
                    primary={orderProduct.item.itemName}
                    secondary={
                      <>
                        <Typography component="span" display="inline-block">
                          {`${orderProduct.item.price} AMD`}
                        </Typography>

                        <Typography component="span" noWrap>
                          {ingredients}
                        </Typography>
                      </>
                    }
                  />
                </Tooltip>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </Stack>
  </Collapse>
);

export default OrderListItemCollapse;
