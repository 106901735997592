import { FC } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { selectIsLoggedIn } from '@/store/slices/auth';
import { useAppSelector } from '@/hooks/store';

const UnProtectedRoutes: FC = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/categories" />;
  }

  return <Outlet />;
};

export default UnProtectedRoutes;
